<template>
  <v-card class="subscription-page rounded-10" elevation="3" min-height="500">
    <v-card-title
      class="primary py-5 light--text"
      v-if="!is_company_subscribed"
    >
      <h1 class="heading-1 fullwidth text-center my-7">
        Get
        <strong class="fs-30">{{ settings.SiteName }} Premium</strong>
      </h1>
    </v-card-title>
    <v-divider></v-divider>
    <v-row
      dense
      align="stretch"
      :class="{ 'flex-nowrap': mdAndUp }"
      justify="space-between"
    >
      <v-col :cols="mdAndUp ? 3 : 12" class="align-self-center">
        <v-sheet color="lighten-2" align="center" class="fill-height pa-5 ml-1">
          <h1 class="heading-1 font-weight-bold primary--text">
            Thank you for choosing our product!
          </h1>
          <h3 class="heading-5 my-3 font-weight-bold primary--text">
            Let's start building your business!
          </h3>
          <v-avatar size="200" class="mt-5">
            <v-img
              :lazy-src="settings.loader"
              contain
              :src="settings.squareLogo"
            ></v-img>
          </v-avatar>
        </v-sheet>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col class="px-1">
        <v-card flat v-if="subscription_enabled">
          <v-card-text>
            <v-row dense class="mb-1">
              <v-col :cols="3"></v-col>
              <v-col
                :cols="3"
                v-for="item in subscribleMainPlans"
                :key="item.id"
              >
                <v-card
                  elevation="3"
                  class="ma-1"
                  height="100"
                  v-if="mdAndUp"
                  :color="item.selected ? 'success' : 'whitesmoke'"
                  @click="selectMainSelected(item)"
                >
                  <v-scale-transition>
                    <v-row class="fill-height" align="center" justify="center">
                      <v-icon class="mx-2" color="primary" x-large>
                        {{ item.icon }}
                      </v-icon>
                      <div class="mx-2">
                        <h1 class="title font-weight-bold primary--text">
                          {{ item.plan_amount | money }} |
                          <span class="overline"> Month </span>
                        </h1>
                        <h2
                          class="
                            subtitle-1
                            fs-20
                            font-weight-bold
                            primary--text
                          "
                        >
                          {{ item.name | ucwords }}
                        </h2>
                      </div>
                    </v-row>
                  </v-scale-transition>
                </v-card>
                <v-sheet class="d-flex fill-height primary light--text" v-else>
                  <strong class="align-self-center text-center mx-auto">
                    {{ item.name }}
                  </strong>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row
              align="center"
              dense
              v-for="feature in allModules"
              :key="feature.slug"
              no-gutters
              class="mx-auto"
            >
              <v-col :cols="3">
                <v-sheet class="rounded px-2 py-1 my-1 primary--text">
                  <v-tooltip right v-if="mdAndUp">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        small
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information
                      </v-icon>
                    </template>
                    <span>{{ feature.description }}</span>
                  </v-tooltip>
                  {{ feature.name }}
                </v-sheet>
              </v-col>
              <v-col :cols="3" class="text-center light--text">
                <v-icon
                  :color="feature.access_for_personal ? 'primary' : 'error'"
                >
                  {{
                    feature.access_for_personal ? 'mdi-check-bold' : 'mdi-close'
                  }}
                </v-icon>
              </v-col>
              <v-col :cols="3" class="text-center light--text">
                <v-icon
                  :color="
                    feature.access_for_small_business ? 'primary' : 'error'
                  "
                >
                  {{
                    feature.access_for_small_business
                      ? 'mdi-check-bold'
                      : 'mdi-close'
                  }}
                </v-icon>
              </v-col>
              <v-col :cols="3" class="text-center light--text">
                <v-icon
                  :color="feature.access_for_agency ? 'primary' : 'error'"
                >
                  {{
                    feature.access_for_agency ? 'mdi-check-bold' : 'mdi-close'
                  }}
                </v-icon>
              </v-col>
            </v-row>
            <v-row dense class="mt-3 d-none">
              <v-col :cols="3" v-if="mdAndUp"></v-col>
              <v-col
                :cols="mdAndUp ? 3 : 12"
                v-for="item in subscribleMainPlans"
                :key="item.id"
              >
                <v-card
                  class="ma-1"
                  height="100"
                  :color="item.selected ? 'success' : 'whitesmoke'"
                  @click="selectMainSelected(item)"
                >
                  <v-scale-transition>
                    <v-row class="fill-height" align="center" justify="center">
                      <v-icon class="mx-2" color="primary" x-large>
                        {{ item.icon }}
                      </v-icon>
                      <div class="mx-2">
                        <h1 class="title font-weight-bold primary--text">
                          {{ item.plan_amount | money }} |
                          <span class="overline"> Month </span>
                        </h1>
                        <h2
                          class="
                            subtitle-1
                            fs-20
                            font-weight-bold
                            primary--text
                          "
                        >
                          {{ item.name | ucwords }}
                        </h2>
                      </div>
                    </v-row>
                  </v-scale-transition>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="!mdAndUp">
              <v-card
                elevation="3"
                class="ma-1 fullwidth"
                height="100"
                v-for="item in subscribleMainPlans"
                :key="item.id"
                :color="item.selected ? 'success' : 'whitesmoke'"
                @click="selectMainSelected(item)"
              >
                <v-scale-transition>
                  <v-row class="fill-height" align="center" justify="center">
                    <v-icon class="mx-2" color="primary" x-large>
                      {{ item.icon }}
                    </v-icon>
                    <div class="mx-2">
                      <h1 class="title font-weight-bold primary--text">
                        {{ item.price | money }} |
                        <span class="overline"> Month </span>
                      </h1>
                      <h2
                        class="subtitle-1 fs-20 font-weight-bold primary--text"
                      >
                        {{ item.name | ucwords }}
                      </h2>
                    </div>
                  </v-row>
                </v-scale-transition>
              </v-card>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <h2 class="font-weight-bold primary--text">Addons</h2>
              </v-col>
              <v-col
                cols="12"
                v-for="(addon, index) in subscribleAddons"
                :key="index"
              >
                <v-checkbox
                  v-model="selectedAddons"
                  :label="addon.name"
                  :value="addon"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card flat v-if="subscription_enabled">
          <v-card-text>
            <v-row dense class="my-1">
              <v-spacer></v-spacer>
              <span class="mr-5 primary--text fs-30 title"> Total : </span>
              <span class="mr-5 primary--text fs-30 title">
                {{ totals | money }}
              </span>
            </v-row>
            <v-alert
              dense
              type="error"
              class="mx-auto my-5"
              outlined
              v-if="!mainSelected && !is_company_subscribed"
            >
              Select main subscription
            </v-alert>
            <v-row dense>
              <v-spacer></v-spacer>
              <v-col md="4">
                <v-select
                  outlined
                  :items="payment_options"
                  item-text="name"
                  item-value="value"
                  hide-details="auto"
                  v-model="pay_with"
                  :disabled="totals < 1 || !has_main_selected"
                ></v-select>
              </v-col>
              <v-col>
                <v-btn
                  color="primary"
                  @click="$router.push({ name: 'subscriptions_pay' })"
                  :disabled="totals < 1 || !has_main_selected"
                  depressed
                  height="55"
                  class="text-none px-6"
                >
                  Proceed To Checkout
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-text>
        </v-card>
        <Empty
          v-else
          min-height="500"
          icon="mdi-credit-card-wireless-off-outline"
          :headline="`Subscriptions is temporarily not available.<br> Continue enjoy our ${settings.SiteName} features.`"
        ></Empty>
      </v-col>
    </v-row>
    <DeleteDialog
      :open-dialog.sync="confirm_unsubscribed_main_dialog"
      text-content="Are you sure you want to unsubscribe from this subscription? This will also unsubscribe its addons."
      title="Confirmation required!"
      @delete="handleUnsubscribed(true)"
      delete-button-text="Yes confirmed"
    ></DeleteDialog>
    <DeleteDialog
      :open-dialog.sync="confirm_unsubscribed_addons_dialog"
      text-content="Are you sure you want to unsubscribe from this addon? "
      title="Confirmation required!"
      @delete="handleUnsubscribed(false)"
      delete-button-text="Yes confirmed"
    ></DeleteDialog>
  </v-card>
</template>

<script>
import request from '@/services/axios_instance'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DeleteDialog from '@/common/DeleteDialog.vue'

export default {
  name: 'Subscriptions',
  components: { DeleteDialog },
  data: () => ({
    confirm_unsubscribed_main_dialog: false,
    confirm_unsubscribed_addons_dialog: false,
    activePlan: null,
    paths: [
      { text: 'Dashboard', disabled: false, router_name: 'default-content' },
      { text: 'Subscriptions', disabled: true, router_name: null }
    ],
    payment_options: [
      {
        value: 'stripe:credit_card',
        name: 'Credit Card via Stripe',
        disabled: false
      }
      // {value: 'paypal', name: 'PayPal', disabled: true},
    ],
    pay_with: 'stripe:credit_card',
    loading: false
  }),
  mounted() {
    this.$event.$emit('path-change', this.paths)
  },
  computed: {
    ...mapGetters('subscriptionv2', [
      'subscribleMainPlans',
      'subscribleAddons',
      'allModules',
      'mainSelected',
      'addonsSelected',
      'totals',
      'is_company_subscribed'
    ]),
    subscription_enabled() {
      return false
    },
    has_main_selected() {
      return !!this.mainSelected || !!this.is_company_subscribed
    },
    selectedAddons: {
      get() {
        return this.addonsSelected
      },
      set(val) {
        this.$store.commit('subscriptionv2/toggleAddonsSelected', val)
      }
    }
  },
  created() {
    //this.getPlans();
  },
  methods: {
    ...mapMutations('subscriptionv2', ['selectMainSelected'])
    // ...mapActions("subscription", [
    //   "getPlans",
    //   "getCurrentSubscriptions",
    //   "unsubscribePlan",
    // ]),
    // ...mapMutations("subscription", [
    //   "addToAddonsSelected",
    //   "setMainSubscription",
    //   "setAddonsSubscriptions",
    //   "setCompanyIsSubscribed",
    // ]),
    // unsubscribe(plan, is_main) {
    //   this.activePlan = plan;
    //   if (is_main) {
    //     this.confirm_unsubscribed_main_dialog = true;
    //     this.confirm_unsubscribed_addons_dialog = false;
    //   } else {
    //     this.confirm_unsubscribed_main_dialog = false;
    //     this.confirm_unsubscribed_addons_dialog = true;
    //   }
    // },
    // handleUnsubscribed(is_main) {
    //   request
    //     .post(`api/subscriptions/unsubscribe`, {
    //       plan_id: this.activePlan.id,
    //       is_main: is_main,
    //     })
    //     .then(({ data }) => {
    //       this.setMainSubscription(data.main);
    //       this.setAddonsSubscriptions(data.addons);
    //       this.setCompanyIsSubscribed(data.is_company_subscribed);
    //       this.activePlan = null;
    //       this.confirm_unsubscribed_main_dialog = false;
    //       this.confirm_unsubscribed_addons_dialog = false;
    //       this.appSnackbar( "Successfully unsubscribed");
    //     });
    // },
  }
}
</script>

<style scoped lang="scss"></style>
